import http from './https'

// 获取公钥
function getLoginPublicKey() {
  return process.env.VUE_APP_PUBLICKEY
}

//更新图片时转base64(url转base64)
function getBase64(imgUrl, options) {
  return new Promise((resolve, reject) => {
    // console.log('-------imgUrl', imgUrl)
    http
      .get(imgUrl, {
        responseType: 'blob',
        ...options
      })
      .then((result) => {
        var blob = result.data
        let oFileReader = new FileReader()
        oFileReader.onloadend = (e) => {
          let base64 = e.target.result
          resolve(base64)
        }
        oFileReader.readAsDataURL(blob)
      })
      .catch((res) => {
        reject(res)
      })
  })
}

/**
 * 格式化时间
 * @param number 时间戳
 * @param format 格式 'Y', 'm', 'd', 'H', 'i', 's'
 *
 * return boolean
 */
function format(number, format) {
  function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
  }

  if (number === null || number === undefined) {
    return ''
  }
  var formateArr = ['Y', 'm', 'd', 'H', 'i', 's']
  var returnArr = []

  // var date = new Date(number * 1000);
  var date = new Date(number)
  returnArr.push(date.getFullYear())
  returnArr.push(formatNumber(date.getMonth() + 1))
  returnArr.push(formatNumber(date.getDate()))

  returnArr.push(formatNumber(date.getHours()))
  returnArr.push(formatNumber(date.getMinutes()))
  returnArr.push(formatNumber(date.getSeconds()))

  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i])
  }

  return format
}

/**
 * 获取用户时区的日期时间
 * @param {String|Number} dateTime 日期时间 | 时间戳 (必需)
 * @param {Number|String} offset 时差 (必需)
 * @returns
 */
function getTimeZoneDateTime(dateTime, offset) {
  if (dateTime && offset) {
    if (isNaN(offset)) return ''
    // 账号的时区，先于协调世界时为负值，后于协调世界时为正值，后台返回的是相反的，所以这里需要取反
    let offsetVal = -Number(offset)
    // 用户当时区
    let currnetOffset = new Date().getTimezoneOffset()
    // 传入时间的时间戳
    let dateTimeStamp = 0
    if (typeof dateTime === 'number') {
      dateTimeStamp = dateTime
    } else {
      dateTimeStamp = new Date(dateTime).getTime()
    }
    // 如果相等就不计算
    if (currnetOffset === offsetVal) {
      return format(dateTimeStamp, 'Y-m-d H:i:s')
    } else {
      let offsetStamp = -(offsetVal - currnetOffset) * 60 * 1000
      return format(dateTimeStamp + offsetStamp, 'Y-m-d H:i:s')
    }
  }
  return ''
}

// 获取浏览器语言并切换
function getBrowserLang() {
  let lang = 'en'
  switch (navigator.language) {
    case 'zh-CN':
      lang = 'zh'
      break
    case 'zh-HK':
      lang = 'zht'
      break
    case 'zh-TW':
      lang = 'tw'
      break
    default:
      lang = 'en'
  }
  return lang
}

// 获取当前最大的zIndex
function getMaxZIndex() {
  let maxZIndex = 0
  document.querySelectorAll('*').forEach((element) => {
    const zIndex = window.getComputedStyle(element).zIndex
    if (zIndex !== 'auto') {
      const parsedZIndex = parseInt(zIndex, 10)
      if (!isNaN(parsedZIndex)) {
        maxZIndex = Math.max(maxZIndex, parsedZIndex)
      }
    }
  })
  return maxZIndex
}

// 失焦事件
function blurFn() {
  if (document && document.activeElement) {
    document.activeElement.blur()
  }
}

/**
 * 创建并下载文件
 * @param  {String} fileName 文件名
 * @param  {String} content  文件内容
 * @param  {String} contentType  文件类型
 */
function download(filename, content, contentType) {
  if (!contentType) contentType = 'application/octet-stream'
  // try{
  const aTag = document.createElement('a')
  const blob = new Blob([content], {
    type: contentType,
  })
  aTag.href = window.URL.createObjectURL(blob)
  aTag.download = filename
  // document.body.appendChild(aTag);
  aTag.click()
  // }catch(e){
  // 	console.log('--download',e)
  // }

  window.URL.createObjectURL(blob)
}

// 获取国内版域名
function getInternalSite() {
  if (window.location.origin == getInternalOtherSite()) return getInternalOtherSite()
  // if(typeof InternalSite == 'string'){
  //   return InternalSite
  // }
  return process.env.VUE_APP_BASE_URL_CN || 'https://test.313fm.com:8444'
  // return 'https://test.313fm.com:8444';
}

// 国内版的其他域名
function getInternalOtherSite() {
  // if(typeof InternalOther == 'string'){
  //   return InternalOther
  // }
  return 'https://313fm.com'
}

// 获取国际版域名
function getInterSite() {
  // if(typeof InterSite == 'string'){
  //   return InterSite
  // }
  return 'https://inter.313fm.com'
}

// 获取另一个版本服务器的域名
function getAnotherSite() {
  if (window.location.origin == getInternalSite() || window.location.origin == getInternalOtherSite()) return getInterSite()
  return getInternalSite()
}

/**
 * 生成唯一id
 * @returns 时间戳三十六进制 + 随机数三十六进制的8位
 */
function generateUid() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2, 10)
}


// function generateStageSvgPathString(stage, config) {
//   const windowX = window.innerWidth;
//   const windowY = window.innerHeight;

//   const stagePadding = (config && config.stagePadding) || 0;
//   const stageRadius = (config && config.stageRadius) || 0;

//   const stageWidth = stage.width + stagePadding * 2;
//   const stageHeight = stage.height + stagePadding * 2;

//   // prevent glitches when stage is too small for radius
//   const limitedRadius = Math.min(stageRadius, stageWidth / 2, stageHeight / 2);

//   // no value below 0 allowed + round down
//   const normalizedRadius = Math.floor(Math.max(limitedRadius, 0));

//   const highlightBoxX = stage.x - stagePadding + normalizedRadius;
//   const highlightBoxY = stage.y - stagePadding;
//   const highlightBoxWidth = stageWidth - normalizedRadius * 2;
//   const highlightBoxHeight = stageHeight - normalizedRadius * 2;

//   return `M${windowX},0L0,0L0,${windowY}L${windowX},${windowY}L${windowX},0Z
//     M${highlightBoxX},${highlightBoxY} h${highlightBoxWidth} a${normalizedRadius},${normalizedRadius} 0 0 1 ${normalizedRadius},${normalizedRadius} v${highlightBoxHeight} a${normalizedRadius},${normalizedRadius} 0 0 1 -${normalizedRadius},${normalizedRadius} h-${highlightBoxWidth} a${normalizedRadius},${normalizedRadius} 0 0 1 -${normalizedRadius},-${normalizedRadius} v-${highlightBoxHeight} a${normalizedRadius},${normalizedRadius} 0 0 1 ${normalizedRadius},-${normalizedRadius} z`;
// }

export default {
  getLoginPublicKey,
  format,
  getTimeZoneDateTime,
  getBrowserLang,
  getMaxZIndex,
  blurFn,
  download,
  getAnotherSite,
  getInternalSite,
  getInterSite,
  getBase64,
  generateUid,
}
